var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"my-5"},[_c('v-row',{staticClass:"pl-5"},[_c('v-col',{staticClass:"col col-lg-10 col-md-10 col-sm-10",attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-search","label":"Поиск","dense":"","outlined":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"row no-gutters justify-center col col-lg-2 col-md-2 col-sm-2",attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"dark":"","color":"primary"},on:{"click":_vm.create}},[_c('span',{staticClass:"hidden-xs-only"},[_vm._v("Создать")]),_c('v-icon',{attrs:{"right":_vm.$vuetify.breakpoint.smAndUp}},[_vm._v("mdi-plus")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.list,"items-per-page":-1,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"search":_vm.search,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event},"item-expanded":_vm.expand},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{staticClass:"ml-1",attrs:{"small":""},on:{"click":function($event){return _vm.del(item)}}},[_vm._v("mdi-delete")])]}},{key:"tags",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-chip-group',{attrs:{"column":""}},_vm._l((item.categories),function(tag){return _c('v-chip',{key:tag.id,attrs:{"value":tag.id,"small":"","disabled":""}},[_vm._v(" "+_vm._s(tag.name)+" ")])}),1)],1)]}},{key:"expanded-item",fn:function(){return [_c('v-container',[_c('v-chip-group',{attrs:{"column":"","multiple":"","active-class":"primary--text"},model:{value:(_vm.selectedTags),callback:function ($$v) {_vm.selectedTags=$$v},expression:"selectedTags"}},_vm._l((_vm.tags),function(tag){return _c('v-chip',{key:tag.id,attrs:{"value":tag.id,"filter":"","outlined":"","small":""},on:{"input":function($event){return _vm.tagSelected(tag)}}},[_vm._v(" "+_vm._s(tag.name)+" ")])}),1)],1)]},proxy:true},{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"align":"center","justify":"center"}},[(item.image)?_c('v-avatar',{staticClass:"grey lighten-2",attrs:{"size":"50"}},[_c('img',{attrs:{"alt":"","src":item.image},on:{"click":function($event){return _vm.handleImageClick(item.id)}}})]):_c('v-avatar',{staticClass:"grey lighten-2",attrs:{"size":"50"}},[_c('img',{attrs:{"alt":"","src":"/silverware.svg"},on:{"click":function($event){return _vm.handleImageClick(item.id)}}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_c('input',{ref:("image-" + (item.id)),attrs:{"type":"file","id":("image-" + (item.id)),"accept":"image/*"},on:{"change":function($event){return _vm.handleImageUpload(item.id)}}})])],1)]}},{key:"item.hidden",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-checkbox',{on:{"change":function($event){return _vm.save(item)}},model:{value:(item.hidden),callback:function ($$v) {_vm.$set(item, "hidden", $$v)},expression:"item.hidden"}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }