<template>
  <v-card class="my-5">
      <v-row class="pl-5">
        <v-col cols="8" class="col col-lg-10 col-md-10 col-sm-10">
          <v-text-field v-model="search"
                        append-icon="mdi-search"
                        label="Поиск"
                        dense
                        outlined
                        single-line
                        hide-details/>
        </v-col>
        <v-col cols="4" class="row no-gutters justify-center col col-lg-2 col-md-2 col-sm-2">
          <v-btn @click="create" dark color="primary">
            <span class="hidden-xs-only">Создать</span>
            <v-icon :right="$vuetify.breakpoint.smAndUp">mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table :headers="headers"
                    :items="list"
                    :items-per-page="-1"
                    :single-expand="singleExpand"
                    :expanded.sync="expanded"
                    :search="search"
                    @item-expanded="expand"
                    show-expand
                    class="elevation-1">
        <template v-slot:item.action="{ item }">
          <v-icon small color="primary" @click="edit(item)">mdi-pencil</v-icon>
          <v-icon class="ml-1" small @click="del(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:tags="{ item }">
          <v-row align="center" justify="center">
            <v-chip-group column>
              <v-chip v-for="tag in item.categories" :key="tag.id" :value="tag.id" small disabled>
                {{ tag.name }}
              </v-chip>
            </v-chip-group>
          </v-row>
        </template>
        <template v-slot:expanded-item>
          <v-container>
            <v-chip-group v-model="selectedTags"
                          column
                          multiple
                          active-class="primary--text">
              <v-chip v-for="tag in tags"
                      :key="tag.id"
                      :value="tag.id"
                      filter
                      outlined
                      @input="tagSelected(tag)"
                      small>
                {{ tag.name }}
              </v-chip>
            </v-chip-group>
          </v-container>
        </template>
        <template v-slot:item.image="{ item }">
          <v-row align="center" justify="center">
            <v-avatar v-if="item.image" size="50" class="grey lighten-2">
              <img alt="" :src="item.image" @click="handleImageClick(item.id)">
            </v-avatar>
            <v-avatar v-else size="50" class="grey lighten-2">
              <img alt="" src="/silverware.svg" @click="handleImageClick(item.id)" />
            </v-avatar>
            <div v-show="false">
              <input type="file"
                     :id="`image-${item.id}`"
                     :ref="`image-${item.id}`"
                     accept="image/*"
                     v-on:change="handleImageUpload(item.id)"/>
            </div>
          </v-row>
        </template>
        <template v-slot:item.hidden="{ item }">
          <v-row align="center" justify="center">
            <v-checkbox v-model="item.hidden" @change="save(item)"></v-checkbox>
          </v-row>
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
import {uuid} from "uuidv4";
import axios from "../../services/axios";

export default {
  data() {
    return {
      search: "",
      expandedItem: {},
      expanded: [],
      selectedTags: [],
      singleExpand: true
    };
  },

  created() {
    let id = this.$route.params.id;
    this.$store.dispatch("items/fetch", id);
    this.$store.dispatch("categories/fetch", id);
  },

  computed: {
    list() {
      return this.$store.getters["items/list"]
    },
    headers() {
      return this.$store.getters["items/headers"]
    },
    tags() {
      return this.$store.getters["categories/list"]
    }
  },

  methods: {
    tagSelected(tag) {
      if (this.expanded.length > 0) {
        let item = this.expanded[0];

        let tags = [];
        if (item.categories) {
          tags = item.categories.filter(i => i.id === tag.id);
        }
        if (this.selectedTags.includes(tag.id)) {
          if (tags === undefined || tags === null || tags.length === 0) {
            this.$store.dispatch("items/addTag", {item, tag})
          }
        } else {
          if (tags.length > 0) {
            this.$store.dispatch("items/removeTag", {item, tag})
          }
        }
      }
    },

    handleImageUpload(id) {
      let file = this.$refs["image-" + id].files[0];
      this.$store.dispatch("items/uploadImage", {id, file});
    },

    handleImageClick(id) {
      this.$refs["image-" + id].click();
    },

    expand(val) {
      let tags = val.item.categories;
      this.selectedTags = [];
      tags.forEach(element => {
        this.selectedTags.push(element.id);
      });
    },

    create() {
      let storeID = this.$route.params.id;
      let id = uuid.Nil;
      this.$router.push({name: 'editItems', params: {id, storeID}})
    },

    edit(item) {
      let storeID = this.$route.params.id;
      this.$router.push({name: 'editItems', params: {id: item.id, storeID: storeID}})
    },

    del(item) {
      this.$confirm(
          "Вы точно хотите удалить блюдо",
          "Внимание",
          "question"
      ).then(() => {
        this.$store.dispatch("items/remove", item);
      });
    },

    save(item) {
      axios({
        url: "/items",
        method: "POST",
        data: item
      }).then(resp => {
        item = resp.data;
      });
    }
  }
};
</script>
